import $ from 'jquery';
import { Livewire } from '../../../../vendor/livewire/livewire/dist/livewire.esm';
import { loadStripe } from '@stripe/stripe-js';

const NAME = 'stripe-card';
const DATA_KEY = 'df.stripe-card';
const DATA_SELECTOR = '[data-stripe-card]';

const Default = {}

const CardOptions = {
    hideIcon: false,
    hidePostalCode: true,
    style: {
        base: {
            '::placeholder': { color: '#a3a3a3' },
            iconColor: '#525252',
            color: '#525252',
            fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontSize: '14px',
            fontWeight: '400',
            fontSmoothing: 'antialiased',
            lineHeight: '24px',
        }
    }
};

const StripeOptions = {}

class StripeCard {
    constructor(element, config) {
        this._container = this._getElement(element);
        this._config = this._getConfig(config);
        this._instance = null;

        if (typeof this._config.id === 'undefined') {
            throw new Error('[STRIPE] No data-id specified on StripeCard element!');
        }

        if (typeof this._config.key === 'undefined') {
            throw new Error('[STRIPE] No data-key specified on StripeCard element!');
        }

        let instanceStripeOptions = typeof this._config.version !== 'undefined'
            ? { apiVersion: this._config.version }
            : {};

        loadStripe(this._config.key, {
            ...this.constructor.StripeOptions,
            ...instanceStripeOptions,
        }).then((stripe) => {
            this._stripe = stripe;
            this._init();
        });
    }

    _init() {
        let cardOptions = {
            ...this.constructor.CardOptions,
        };

        // Overload the card styling when dark mode is enabled...
        if (document.getElementsByTagName('html')[0].classList.contains('dark')) {
            cardOptions.style.base['::placeholder'].color = '#525252';
            cardOptions.style.base.color = '#d4d4d4';
            cardOptions.style.base.iconColor = '#d4d4d4';
        }

        // Create and mount a new card element...
        this._instance = this._stripe.elements().create('card', cardOptions);
        this._instance.mount(this._container);

        Livewire.on('stripe-card:intent', event => {
            if (event.id === this._config.id) {
                this._processIntent(event.secret, event.payload);
            }
        });
        Livewire.on('stripe-card:clear', event => {
            if (event.id === this._config.id) {
                this._instance.clear();
            }
        });
    }

    async _processIntent(secret, payload) {
        const {setupIntent, error} = await this._stripe.confirmCardSetup(secret, {
            payment_method: {
                card: this._instance,
                billing_details: payload,
            },
        });

        if (error) {
            console.error(error);
            Livewire.find(this._config.id).cardError(error.message);
        } else {
            Livewire.find(this._config.id).updatePaymentMethod(setupIntent.payment_method);
        }
    }

    /**
     * Get the prepared config.
     *
     * @param  {Object} config
     * @return {Object}
     */
    _getConfig(config) {
        return {
            ...this.constructor.Default,
            ...$(this._container).data(),
            ...config
        };
    }

    /**
     * Determine if the obj is an element.
     *
     * @param  {mixed} obj
     * @return {bool}
     */
    _isElement(obj) {
        if (!obj || typeof obj !== 'object') {
            return false;
        }

        if (typeof obj.jquery !== 'undefined') {
            obj = obj[0];
        }

        return typeof obj.nodeType !== 'undefined';
    }

    /**
     * Get the normalized element.
     *
     * @param  {mixed} obj
     * @return {mixed}
     */
    _getElement(obj) {
        // It's a jQuery object or a node element...
        if (this._isElement(obj)) {
            return obj.jquery ? obj[0] : obj;
        }

        if (typeof obj === 'string' && obj.length > 0) {
            return document.querySelector(obj);
        }

        return null;
    }

    static get Default() {
        return Default;
    }

    static get CardOptions() {
        return CardOptions;
    }

    static get StripeOptions() {
        return StripeOptions;
    }

    static _jQueryInterface(config, params) {
        return this.each(function () {
            const $element = $(this);
            let data = $element.data(DATA_KEY);

            const _config = typeof config === 'object' && config;
            const _params = typeof params === 'object' && params;

            if (!data) {
                data = new StripeCard(this, _config);
                $element.data(DATA_KEY, data);
            }

            if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`);
                }

                _params ? data[config](..._params) : data[config]();
            }

            return data;
        })
    }
}

window.addEventListener('load', () => {
    const stripeCards = [].slice.call(document.querySelectorAll(DATA_SELECTOR));

    for (let i = 0, len = stripeCards.length; i < len; i++) {
        const $element = $(stripeCards[i]);
        StripeCard._jQueryInterface.call($element, $element.data());
    }
});

const JQUERY_NO_CONFLICT = $.fn[NAME];

$.fn[NAME] = StripeCard._jQueryInterface;
$.fn[NAME].Constructor = StripeCard;
$.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return StripeCard._jQueryInterface;
}

export default StripeCard;
